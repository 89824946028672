body {
	font-size: 1.2rem;
	font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
		Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
	/* width: 100%; */
	height: 40rem;
	background: url("https://www.evangadi.com/themes/humans/assets/images/misc/bg-svg-f.svg")
		center no-repeat;
	background-color: #f8f8f8;
	background-size: cover;
}
.nav {
	width: 100%;
	background-color: white;
	position: fixed;
	top: 0;
	z-index: 1;
	padding: 2rem;
}
.nav__center {
	display: flex;
	justify-content: center;
	align-items: center;
}
.nav__img {
	margin-right: 18rem;
}
.nav__list a {
	text-decoration: none;
	color: black;
}
.link {
	margin: 0 1.5rem;
}
.nav__btn {
	cursor: pointer;
	padding: 0.5rem 3rem;
	text-align: center;
	font-weight: 700;
	background-color: rgb(170, 109, 214);
	border-radius: 0.3rem;
	border-color: transparent;
	width: 200px;
	color: #fff;
	line-height: 25px;
}
.nav__btn :hover {
	background-color: burlywood;
}
