.home {
	margin-top: 10rem;
	align-items: center;
	text-align: center;
}
.home__title {
	display: flex;
	justify-content: space-around;
}
.home__userName {
	text-align: center;
	align-items: center;
}
.home__askQuestion {
	cursor: pointer;
	padding: 0.6rem;
	width: 10rem;
	border-radius: 0.3rem;
	text-align: center;
	align-items: center;
	background-color: rgb(170, 109, 214);
	color: white !important;
}
.home__askQuestion:hover {
	background-color: burlywood;
}

.home__questionTitle {
	text-align: center;
	color: indigo;
}
.home__questionTitle {
	margin-top: 4rem;
	margin-bottom: 1rem;
}
.img {
	width: 6rem;
	border-radius: 50%;
}
.home__UserandQusetion {
	padding: 1rem 2rem 1rem 2rem;
	border-top: 1px solid #ccc;
	border-radius: 1.5rem;
	display: flex;
	justify-content: space-between;
	margin-left: 14rem;
	margin-right: 14rem;
	box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
	margin-bottom: 1rem;
}

.home__Q {
	align-items: center !important;
	text-align: center !important;
	display: flex;
	justify-content: space-evenly;
}
.home__Q :hover {
	color: green !important;
	font-size: 1.4rem;
}

.home__Qtn {
	color: black !important;
}
